@import '~@angular/cdk/overlay-prebuilt.css';

@font-face {
    font-family: "72";
    src: url("~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Regular-full.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "72";
    src: url("~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Bold-full.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "72";
    src: url("~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Light-full.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "SAP-icons";
    src: url("~@sap-theming/theming-base-content/content/Base/baseLib/sap_horizon/fonts/SAP-icons.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BusinessSuiteInAppSymbols';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/BusinessSuiteInAppSymbols.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SAP-icons-TNT';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/SAP-icons-TNT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 16px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.modal {
    width: 850px;
    overflow: hidden; /* will contain if #first is longer than #second */
}

.first {
    width: 250px;
    float: left; 
}

.second {
    padding-left: 50px;
    overflow: hidden; /* if you don't want #second to wrap below #first */
}

.left {
    
    float:left; 

    fdp-table {
        width: 500px;
    }
}

.right {
    padding-left: 50px;
    overflow: hidden; /* if you don't want #second to wrap below #first */

    table {
        width: 700px;
    }
}

.active {
    background-color: #d9d9d9 !important;    
}

#menu-funkce {
    display: none;
}

#menu-smena {
    display: none;
}

fd-segmented-button {
    position: fixed !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 600px;
    height: 30px;
    padding: 5px;
}

#ukonceni-smeny-left-table {
    height: 400px; 
    width: 700px;
    display: inline-block;
    overflow-y: scroll;

    .td-text-right {
        text-align: right;
    }
}

#ukonceni-smeny-right-table {
    float: right;
    height: 300px;
    width: 500px;
    overflow-y: scroll;
}

#ukonceni-smeny-custom-table {
    position: relative;
    height: 300px;
    width: 500px;
    padding: 20px 0px 0px 10px;

    .custom-left {
        float: left; 
        width: 150px;
    }
    
    .custom-right {
        width: 150px;
        padding-left: 160px;
    }
}

#vyrobky-skladem-left-table {
    height: 900px; 
    width: 700px;
    display: inline-block;
    overflow-y: scroll;

    .td-text-right {
        text-align: right;
    }
}

#vyrobky-skladem-right-table {
    float: right;
    height: 900px;
    width: 500px;
    overflow-y: scroll;

    .td-text-right {
        text-align: right;
    }
}

#editace-smeny {
    height: 900px; 
    width: 1100px;
    display: inline-block;
    overflow-y: scroll;

    .td-text-right {
        text-align: right;
    }
}

.footer {
    position: relative;
    padding: 500px 0px 0px 10px;
    .konec-smeny {
        position: fixed;
        right: 0px; bottom: 45px;
        width: 200px;
        height:40px;
        background-color:lightblue;
        padding: 5px;
    }

    .end {
        position: fixed;
        right: 0px; bottom: 0px;
        width: 200px;
        height:40px;
        background-color:lightblue;
        padding: 5px;
    }

    .back {
        position: fixed;
        left: 0px; bottom: 0;
        width: 200px;
        height:40px;
        background-color:lightblue;
        padding: 5px;
    }

    .brambory {
        display: none;
        position: fixed;
        left: 0px; bottom: 45px;
        width: 200px;
        height:40px;
        background-color:lightblue;
        padding: 5px;
    }

    .seznam-expedice {
        position: fixed;
        left: 210px; bottom: 0px;
        width: 200px;
        height:40px;
        background-color:lightblue;
        padding: 5px;
    }
}

.breadcrumb {
    padding: 5px;
    font-family: "72", "72full", Arial, Helvetica, sans-serif;
}

fd-dialog-footer {
    p {
        width: 100%;
    }
    
}